import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SyntheticFont from '../../components/SyntheticFont';
import SmallerContainer from '../../components/SmallerContainer';
import Quote from '../../components/Quote';
import { quotes } from '../../../data/data';


const style = (theme) => ({
    root: {
        minHeight: '510px',
        padding: '75px 0px',
        boxSizing: 'border-box',
        textAlign: 'center',
        flexGrow: 1,
        backgroundImage: 'url("https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/feedback-background.png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    overflow: {
        overflowX: 'hidden'
    },
    textItem: {
        textAlign: 'center',
        // marginBottom: '30px',
        margin:'0px 20px 30px 20px'
    },
    image: {
        display: 'block',
        margin: 'auto'
    },
    syntheticFont: {
        marginBottom: '90px'
    },
    syntheticFontUpper: {
        fontSize: '24px',
        color: '#FFFFFF'
    },
    syntheticFontLower: {
        fontSize: '24px',
        color: 'rgba(38, 108, 0, 0.36)',
        letterSpacing: '1px'
    }
});

class CustomerFeedback extends React.Component {

    render() {
        const classes = this.props.classes;


        return (
            <div className={classes.root}>
                <SmallerContainer>
                    <Grid item xs={12}>
                        <SyntheticFont
                            className={classes.syntheticFont}
                            inheritedClasses={{
                                upperText: classes.syntheticFontUpper,
                                lowerText: classes.syntheticFontLower
                            }}
                            upperText={'用户反馈'}
                            lowerText={'Customer Feedback'}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                        spacing={3}
                        className={classes.overflow}
                    >
                        {
                            quotes.map((quote, index) => {
                                return (
                                    <Grid item className={classes.textItem} key={index}>
                                        <Quote quote={quote} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </SmallerContainer>

            </div>
        );
    }
}

export default withStyles(style)(CustomerFeedback);
