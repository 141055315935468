import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = {
    root: {
        minHeight: '192px',
        padding: '136px 30px 0px 30px',
        boxSizing: 'border-box',
        textAlign: 'center',
        flexGrow: 1,
        backgroundImage: 'url("https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/footer-background.png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    copyright: {
        fontSize: '14px',
        color: '#FFFFFF',
        // padding: '136px 30px 0px 30px'
    },
    link: {
        textDecoration: 'none',
        color: '#FFFFFF',
        cursor: 'pointer',
        ouline: 'none'
    }
};

class Footer extends React.Component {
    render() {
        const classes = this.props.classes;

        return (
            <Paper className={classes.root} square={true}>
                <Typography className={classes.copyright} component="p" align="center">智慧数据分析 Copyright  2022 Pinshen. All Rights Reserved.</Typography>
                <Typography className={classes.copyright} component="p" align="center">
                    <a href={'https://beian.miit.gov.cn/'} target={'_blank'} className={classes.link}>浙ICP备2021038607号-1</a>
                </Typography>
            </Paper>
        );
    }
}

export default withStyles(styles)(Footer);
