import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Header from './shared/containers/Header';
import CoreTech from './shared/containers/CoreTech';
import Applications from './shared/containers/Applications';
import Products from './shared/containers/Products';
import CustomerFeedback from './shared/containers/CustomerFeedback';
import AboutUs from './shared/containers/AboutUs';
import Footer from './shared/containers/Footer';
import Grid from '@material-ui/core/Grid';
import Section from './shared/components/Section';

const styles = {
    layer1: {
        flexGrow: 1,
        backgroundImage: 'url("https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/layer1.png")',
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '1620px'
    },
    layer2: {
        flexGrow: 1,
        backgroundImage: 'url("https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/layer2.png")',
        backgroundPosition: 'right 0px top 918px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '4084px'
    },
    phoneSection: {
        minHeight: '167px',
        backgroundColor: '#f9fcfd'
    },
    phoneWrapper: {
        paddingTop: '50px',
        paddingBottom: '50px',
        paddingLeft: '30px',
        paddingRight: '30px',
    },
    phoneTitleImage: {
        marginRight: 30,
        marginBottom: 15
    },
    phoneTextTitle: {
        marginBottom: 15
    },
    phoneText: {
        display: 'inline-block',
        verticalAlign: 'top'
    },
    phoneImages: {
        marginLeft: '18px',
        marginRight: '18px',
        width: '250px',
        display: 'inline-block'
    },
    phoneImagesCaption: {
        fontSize: '18px',
        color: '#1d1d1d'
    }
}

class App extends React.Component {

    render() {
        const classes = this.props.classes;
        return (
            <div className="App">
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12}>

                        <Section>
                            <a name="首页"></a>
                            <Header />
                        </Section>

                        <div className={classes.layer1}>
                            <div className={classes.layer2}>
                                <Section>
                                    <a name="核心能力"></a>
                                    <CoreTech />
                                </Section>

                                <Section>
                                    <a name="应用场景"></a>
                                    <Applications />
                                </Section>

                                {/*<Section>*/}
                                {/*    <a name="产品介绍"></a>*/}
                                {/*    <Products />*/}
                                {/*</Section>*/}

                                {/*<Section>*/}
                                {/*    <a name="用户反馈"></a>*/}
                                {/*    <CustomerFeedback />*/}
                                {/*</Section>*/}

                                <Section>
                                    <a name="关于我"></a>
                                    <AboutUs />
                                </Section>
                                <Footer />
                            </div>
                        </div>

                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(App);
