import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#FFF'
        },
        secondary: {
            main: '#f3465a'
        },
        textPrimary: '#000',
        textSecondary: '#888'
    },
    typography: {
        fontFamily: [
            'Microsoft YaHei',
            'PingFangSC',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
        h1: {
            fontSize: '64px',
            marginTop: 20,
            marginBottom: 20
        },
        h5: {
            fontSize: '24px'
        },
        h6: {
            fontSize: '20px'
        },
        body1: {
            fontSize: '18px'
        },
        body2: {
            fontSize: '16px'
        },
        caption: {
            fontSize: '14px'
        }
    },
});

ReactDOM.render(<ThemeProvider theme={theme}><App /></ThemeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
