import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { List, ListItem, ListItemText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Menu, MenuItem } from '@material-ui/core';

const style = (theme) => ({
    root: {
        position: 'relative',
        flexGrow: 1,
        backgroundImage: 'url("https://dietlens-1258665547.cos.ap-shanghai.myqcloud.com/mini-app-image/defaultImage/website-header-background.png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '970px'
    },
    appBar: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        boxShadow: 'none'
    },
    appBarToolbar: {
        height: '82px',
        justifyContent: 'space-between',
        padding: '0px 10% 0px 5%'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        marginLeft: '15px'
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
    },
    menuItem: {
        display: 'block',
        marginRight: '20px'
    },
    logo: {
        height: '42px',
        width: '234px'
    },
    CNtext: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: 'rgba(246, 253, 242, 1)',
        textAlign: 'center'
    },
    ENGtext: {
        fontSize: '16px',
        fontWeight: '400',
        color: 'rgba(242, 251, 234, 1)',
        textAlign: 'center'
    },
    colorYellow: {
        color: '#FFD800'
    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        color: '#FFFFFF',
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    textContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    mainTextArea: {
        display: 'flex',
        justifyContent: 'center',
        alighItems: 'center',
        flexWrap: 'wrap'
        // padding: '0px 20px',
    },
    mainText1: {
        padding: '0px 20px',
        fontSize: '56px',
        color: '#FFFFFF',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    },
    mainText2: {
        padding: '0px 15px',
        fontSize: '25px',
        color: '#FFFFFF',
        fontWeight: '400'
    }
});

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null
        };
    }

    openMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    closeMenu = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const classes = this.props.classes;
        const list = [
            { 'text': '首页', 'ENGtext': 'Home', 'link': '首页' },
            { 'text': '核心能力', 'ENGtext': 'Core tech', 'link': '核心能力' },
            // { 'text': '应用场景', 'ENGtext': 'Applications', 'link': '应用场景' },
            // { 'text': '产品介绍', 'ENGtext': 'Products', 'link': '产品介绍' },
            { 'text': '关于我', 'ENGtext': 'About us', 'link': '关于我' }
        ];

        return (
            <div className={classes.root}>
                <AppBar position="relative" color="primary" className={classes.appBar}>
                    <Toolbar className={classes.appBarToolbar}>
                        {/*公司logo*/}
                        {/*<img className={classes.logo} src="https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/new-logo.png" alt="Pinshen logo" />*/}

                        <div className={classes.sectionDesktop}>
                            <List className={classes.menu}>
                                {
                                    list.map((item, i) => {
                                        if (i === 0) {
                                            return (
                                                <a key={i} className={classes.link} href={"#" + item.link}>
                                                    <ListItem className={classes.menuItem} disableGutters={true}>
                                                        <ListItemText disableTypography={true} className={classes.CNtext + ' ' + classes.colorYellow} primary={item.text} />
                                                        <ListItemText disableTypography={true} className={classes.ENGtext + ' ' + classes.colorYellow} primary={item.ENGtext} />
                                                    </ListItem>
                                                </a>
                                            )
                                        }
                                        else {
                                            return (
                                                <a key={i} className={classes.link} href={"#" + item.link}>
                                                    <ListItem className={classes.menuItem} disableGutters={true}>
                                                        <ListItemText disableTypography={true} className={classes.CNtext} primary={item.text} />
                                                        <ListItemText disableTypography={true} className={classes.ENGtext} primary={item.ENGtext} />
                                                    </ListItem>
                                                </a>
                                            )
                                        }
                                    })
                                }
                            </List>
                        </div>

                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-haspopup="true"
                                onClick={this.openMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>

                            <Menu
                                anchorEl={this.state.anchorEl}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                keepMounted
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.closeMenu}>
                                {list.map((item, i) => {
                                    return (
                                        <a key={i} onClick={this.closeMenu} className={classes.link} href={"#" + item.link}>
                                            <MenuItem>
                                                <Typography variant="body1" color="textPrimary">{item.text}</Typography>
                                            </MenuItem>
                                        </a>
                                    );
                                })}
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>

                <div className={classes.textContainer}>
                    <div className={classes.mainTextArea}>
                        <Typography className={classes.mainText1} align="center">智能营养师</Typography>
                        <Typography className={classes.mainText1} align="center">精准个性化</Typography>
                    </div>
                    {/* <Typography className={classes.mainText1} align="center">智能营养师  精准个性化</Typography> */}
                    <Typography className={classes.mainText2} align="center">一键分析营养状况、饮食评估、定制食谱、查询食物营养</Typography>
                </div>
            </div>
        );
    }
}

export default withStyles(style)(Header);
