import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SyntheticFont from '../../components/SyntheticFont';
import SmallerContainer from '../../components/SmallerContainer';
import RectangleLabel from '../../components/RectangleLabel';
import Qrcode from '../../components/Qrcode';
import { thinks, foundingTeam, universities, collaboratorIcons, collaborators, qrcodes } from '../../../data/data';



const style = (theme) => ({
    root: {
        padding: '82px 0px',
        boxSizing: 'border-box',
        textAlign: 'center'
    },
    syntheticFont: {
        marginBottom: '90px'
    },
    memberContainer: {
        marginBottom: '50px'
    },
    memberItem: {
        marginBottom: '25px'
    },
    memberImage: {
        marginBottom: '14px'
    },
    memberPosition: {
        fontSize: '14px',
        color: '#211B13'
    },
    memberName: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#211B13',
        marginBottom: '17px'
    },
    memberDescription: {
        fontSize: '14px',
        color: '#211B13',
        whiteSpace: 'pre-line'
    },
    marginBottom0: {
        marginBottom: '20px'
    },
    marginBottom1: {
        marginBottom: '70px'
    },
    marginBottom2: {
        marginBottom: '110px'
    },
    margin1: {
        margin: '0px 20px'
    },
    margin2: {
        margin: '0px 40px'
    },
    collaboratorIconsContainer: {
        marginBottom: '70px',
        overflowX: 'hidden'
    },
    collaboratorIconsText: {
        position: 'absolute',
        bottom: '0px',
        left: '50%',
        transform: 'translate(-50%, -10px)',
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#211B13',
        whiteSpace: 'nowrap'
    },
    text: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#211B13',
        marginBottom: '37px'
    },
    image: {
        display: 'block',
        margin: 'auto'
    },
    iconContainer: {
        position: 'relative',
        margin: 'auto',
        height: '150px',
        width: '150px'
    },
    iconImage: {
        position: 'absolute',
        top: '50px',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
});

class AboutUs extends React.Component {

    render() {
        const classes = this.props.classes;
        const containerConfig1 = { lg: 9, md: 9, sm: 7 }
        // const containerConfig2 = { lg: 9, md: 10, sm: 7 }
        // const containerConfig3 = { lg: 8, md: 10, sm: 9, xs: 10 }
        // const containerConfig4 = { lg: 8, md: 10, sm: 9 }

        return (
            <div className={classes.root}>
                <SmallerContainer config={containerConfig1} >
                    <Grid item xs={12}>
                        <SyntheticFont className={classes.syntheticFont} upperText={'关于我'} lowerText={'About us'} />
                        {/*<RectangleLabel className={classes.marginBottom1} labelName={'创始团队/Founding team'}></RectangleLabel>*/}
                    </Grid>
                    <Grid
                        className={classes.memberContainer}
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        spacing={0}
                    >
                        {
                            foundingTeam.map((member) => {
                                return (
                                    <Grid item xs={12} sm={6} md={3} className={classes.memberItem} key={member.name}>
                                        <img className={classes.memberImage} width={'164px'} height={'158px'} src={member.image} alt={member.title} />
                                        {/*<Typography className={classes.memberPosition} align="center">{member.position}</Typography>*/}
                                        <Typography className={classes.memberName} align="center">{member.name}</Typography>
                                        <Typography className={classes.memberDescription} align="center">{member.description}</Typography>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <SyntheticFont className={classes.syntheticFont} upperText={'鸣 谢'} lowerText={'Thinks'} />*/}
                    {/*</Grid>*/}
                    {/*<Grid*/}
                    {/*    className={classes.memberContainer}*/}
                    {/*    container*/}
                    {/*    direction="row"*/}
                    {/*    justify="center"*/}
                    {/*    alignItems="flex-start"*/}
                    {/*    spacing={0}*/}
                    {/*>*/}
                    {/*    {*/}
                    {/*        thinks.map((member) => {*/}
                    {/*            return (*/}
                    {/*                <Grid item xs={12} sm={6} md={3} className={classes.memberItem} key={member.name}>*/}
                    {/*                    <img className={classes.memberImage} width={'164px'} height={'158px'} src={member.image} alt={member.title} />*/}
                    {/*                    /!*<Typography className={classes.memberPosition} align="center">{member.position}</Typography>*!/*/}
                    {/*                    <Typography className={classes.memberName} align="center">{member.name}</Typography>*/}
                    {/*                    <Typography className={classes.memberDescription} align="center">{member.description}</Typography>*/}
                    {/*                </Grid>*/}
                    {/*            );*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</Grid>*/}
                </SmallerContainer>
                {/*<SmallerContainer config={containerConfig2}>*/}
                {/*    <Grid*/}
                {/*        container*/}
                {/*        direction="row"*/}
                {/*        justify="center"*/}
                {/*        alignItems="center"*/}
                {/*        className={classes.marginBottom2}*/}
                {/*        spacing={3}*/}
                {/*    >*/}
                {/*        {*/}
                {/*            universities.map((item) => {*/}
                {/*                return (*/}
                {/*                    <Grid item xs={12} sm={6} md={'auto'} key={item.caption}>*/}
                {/*                        <img src={item.image} alt={item.caption} />*/}
                {/*                    </Grid>*/}
                {/*                );*/}
                {/*            })*/}
                {/*        }*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12}>*/}
                {/*        <SyntheticFont className={classes.syntheticFont} upperText={'合 作'} lowerText={'Collaborators'} />*/}
                {/*        /!*<RectangleLabel className={classes.marginBottom1} labelName={'合 作/Collaborators'}></RectangleLabel>*!/*/}
                {/*    </Grid>*/}
                {/*</SmallerContainer>*/}
                {/*<SmallerContainer config={containerConfig3}>*/}
                {/*    <Grid*/}
                {/*        container*/}
                {/*        direction="row"*/}
                {/*        justify="center"*/}
                {/*        alignItems="center"*/}
                {/*        className={classes.collaboratorIconsContainer}*/}
                {/*        spacing={3}*/}
                {/*    >*/}
                {/*        {*/}
                {/*            collaboratorIcons.map((item) => {*/}
                {/*                return (*/}
                {/*                    <Grid item xs={6} sm={4} md={2} key={item.text} >*/}
                {/*                        <div className={classes.iconContainer}>*/}
                {/*                            <img className={classes.iconImage} src={item.image} alt={item.text} />*/}
                {/*                            <Typography className={classes.collaboratorIconsText} align="center">{item.text}</Typography>*/}
                {/*                        </div>*/}
                {/*                    </Grid>*/}
                {/*                );*/}
                {/*            })*/}
                {/*        }*/}
                {/*    </Grid>*/}
                {/*</SmallerContainer>*/}
                {/*<SmallerContainer config={containerConfig4}>*/}
                {/*    <Grid item xs={12}>*/}
                {/*        <SyntheticFont*/}
                {/*            className={classes.syntheticFont}*/}
                {/*            inheritedClasses={{*/}
                {/*                upperText: classes.syntheticFontUpper,*/}
                {/*                lowerText: classes.syntheticFontLower*/}
                {/*            }}*/}
                {/*            upperText={'他们正在使用'}*/}
                {/*            lowerText={'They are using'} />*/}
                {/*        /!*<Typography className={classes.text}>他们正在使用</Typography>*!/*/}
                {/*    </Grid>*/}
                {/*    <Grid*/}
                {/*        container*/}
                {/*        direction="row"*/}
                {/*        justify="center"*/}
                {/*        alignItems="center"*/}
                {/*        className={classes.marginBottom2}*/}
                {/*        spacing={3}*/}
                {/*    >*/}
                {/*        {*/}
                {/*            collaborators.map((item) => {*/}
                {/*                return (*/}
                {/*                    <Grid item xs={6} sm={4} md={2} key={item.caption}>*/}
                {/*                        <img src={item.image} alt={item.caption} />*/}
                {/*                    </Grid>*/}
                {/*                );*/}
                {/*            })*/}
                {/*        }*/}
                {/*    </Grid>*/}
                {/*    /!*<Grid item xs={12}>*!/*/}
                {/*    /!*    <RectangleLabel className={classes.marginBottom1} labelName={'联系我们/Contact us'}></RectangleLabel>*!/*/}
                {/*    /!*</Grid>*!/*/}
                {/*    /!*<Grid*!/*/}
                {/*    /!*    container*!/*/}
                {/*    /!*    direction="row"*!/*/}
                {/*    /!*    justify="center"*!/*/}
                {/*    /!*    alignItems="center"*!/*/}
                {/*    /!*    className={classes.marginBottom0}*!/*/}
                {/*    /!*    spacing={3}*!/*/}
                {/*    /!*>*!/*/}
                {/*    /!*    {*!/*/}
                {/*    /!*        qrcodes.map((item) => {*!/*/}
                {/*    /!*            return (*!/*/}
                {/*    /!*                <Grid item key={item.title}>*!/*/}
                {/*    /!*                    <Qrcode title={item.title} image={item.image} />*!/*/}
                {/*    /!*                </Grid>*!/*/}
                {/*    /!*            );*!/*/}
                {/*    /!*        })*!/*/}
                {/*    /!*    }*!/*/}
                {/*    /!*</Grid>*!/*/}
                {/*</SmallerContainer>*/}

            </div >
        );
    }
}

export default withStyles(style)(AboutUs);
