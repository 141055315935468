import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


const style = (theme) => ({

});

class SmallerContainer extends React.Component {

    render() {
        const { classes, config = {}, className } = this.props;
        const { xs = 10, spacing = 3, justify = 'center', alignItems = 'center', direction = 'row', sm = false, md = false, lg = false } = config;

        return (
            <React.Fragment>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid
                        className={className}
                        item
                        container
                        xs={xs}
                        sm={sm}
                        md={md}
                        lg={lg}
                        direction={direction}
                        justify={justify}
                        alignItems={alignItems}
                        spacing={spacing}
                    >
                        {
                            this.props.children
                        }
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(style)(SmallerContainer);
