import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const style = {
    root: {
        marginBottom: '30px'
    },
    text: {
        fontSize: '36px',
        color: '#000000',
        marginTop: '45px'
    },
    line: {
        backgroundColor: '#f3465a',
        borderRadius: '100px',
        width: '35px',
        height: '2px'
    },
};

class Title extends React.Component {
    render() {
        const classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Typography className={classes.text} component="p" align="left">{this.props.text}</Typography>
                <hr className={classes.line} align="left" />
            </div>
        );
    }
}

export default withStyles(style)(Title);
