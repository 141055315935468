import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const style = (theme) => ({
    root: {
        position: 'relative',
        textAlign: 'center'
    },
    upperText: {
        width: '100%',
        position: 'relative',
        fontSize: '36px',
        fontWeight: 'bold',
        color: '#222222',
        letterSpacing: '4px',
        zIndex: '100'
    },
    lowerText: {
        width: '100%',
        position: 'absolute',
        top: '11px',
        fontSize: '42px',
        color: 'rgba(38, 108, 0, 0.18)',
        letterSpacing: '2px',
        whiteSpace: 'nowrap'
    }
});

class SyntheticFont extends React.Component {

    render() {
        const { classes, upperText, lowerText, className, inheritedClasses = {} } = this.props;
        let { upperText: upperTextWrap, lowerText: lowerTextWrap } = classes;
        let classNameWrap = classes.root;

        if (className) {
            classNameWrap += ' ' + className;
        }
        if (inheritedClasses.upperText) {
            upperTextWrap += ' ' + inheritedClasses.upperText;
        }
        if (inheritedClasses.lowerText) {
            lowerTextWrap += ' ' + inheritedClasses.lowerText;
        }

        return (
            <div className={classNameWrap}>
                <Typography className={upperTextWrap}>{upperText}</Typography>
                <Typography className={lowerTextWrap}>{lowerText}</Typography>
            </div>
        );
    }
}

export default withStyles(style)(SyntheticFont);
