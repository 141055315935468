import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Title from './components/Title';

const style = {
    root: {

    }
};

class Section extends React.Component {
    render() {
        const classes = this.props.classes;

        return (
            <div className={classes.root}>
                {this.props.hasOwnProperty("title") &&
                    <Title text={this.props.title} />
                }

                {this.props.children}
            </div>
        );
    }
}

export default withStyles(style)(Section);
