import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const style = {
    root: {
        display: 'inline-block',
        padding: '11px 37px',
        backgroundColor: '#5EB85A',
        textAlign: 'center',
        borderRadius: '12px'
    },
    flex: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textPart: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#FFFFFF',
        whiteSpace: 'nowrap'
    },
    text: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#FFFFFF'
    }
};

class RectangleLabel extends React.Component {
    render() {
        const { classes, labelName, className } = this.props;
        let classNameWrap = classes.root;
        if (className) {
            classNameWrap += ' ' + className;
        }

        if (labelName.indexOf('/') > -1) {
            const part1 = labelName.substring(0, labelName.indexOf('/') + 1);
            const part2 = labelName.substring(labelName.indexOf('/') + 1);

            return (
                <div className={classNameWrap}>
                    <div className={classes.flex}>
                        <Typography className={classes.textPart}>{part1}</Typography>
                        <Typography className={classes.textPart}>{part2}</Typography>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={classNameWrap}>
                    <Typography className={classes.text}>{labelName}</Typography>
                </div>
            );
        }
        const part1 = labelName.indexOf('/')

        // return (
        //     <div className={classNameWrap}>
        //         <Typography className={classes.text}>{labelName}</Typography>
        //     </div>
        // );
    }
}

export default withStyles(style)(RectangleLabel);
