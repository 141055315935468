export const coreTech_icons = [
    { 'title': 'AI食物识别', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/core-tech-icon1.png', 'description': '行业领先的图片识别技术，极速识别每餐食物' },
    { 'title': '精准知识图谱', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/core-tech-icon2.png', 'description': '国际最新营养学标准' },
    { 'title': 'AI智能营养师', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/core-tech-icon3.png', 'description': '精准评估，个性化饮食推荐指导' }
];
export const applications = [
    { 'title': '减脂', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/reduce-fat.png' },
    { 'title': '增肌', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/gain-muscle.png' },
    { 'title': '血糖控制', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/blood-suger-control.png' },
    { 'title': '孕期营养', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/nutrition-during-pregnancy.png' }
];
export const shadowOverlay = {
    'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/shadow-overlay.png'
}
export const phone_images = [
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/phone1.png', 'title': '饮食记录' },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/phone2.png', 'title': '餐后分析' },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/phone3.png', 'title': '每日营养分析' },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/phone4.png', 'title': '查营养' },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/phone5.png', 'title': '咨询营养师' }
];
export const quotes = [
    { 'content': '“我觉得知食现在的做法很赞，能够很高效的让营养师迅速掌握客户的基本情况，并且营养师部分的模版也能让营养师很便捷的给出对客户有帮助的指导。”', 'author': '李茜  营养师健康管理师' },
    { 'content': '“这个平台能让我们吃得更明白更健康，是我们的福气！ 你们太棒了！很幸运我能参加这个活动。之前我还在想要是有推荐每天吃哪些食物就好了，后来知道后面真的有推荐，真是太好了！这样就不用想破脑袋每天该吃啥了。”', 'author': '王W**gela  两个孩子的妈妈' },
    { 'content': '“孕期我的问题很多，贫血，孕糖，体重超标，可谓焦头烂额。 知食小程序上的营养师和工具，给我很踏实的陪伴，给了我补铁的日常食物建议，比铁剂补充更舒服。还发现我补钙超量了。我的体重和血糖也得到了比较合理的控制。真的感谢他们！“', 'author': '娟*  孕31周准妈妈' }
];
export const foundingTeam = [
    { 'name': '明朝燕', 'position': '首席执行官', 'description': '新加坡国立大学博士\n新加坡国立大学医学院讲师\n浙江大学计算机学院研究员', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team1.png' }
    // { 'name': '蔡达成', 'position': '科学家', 'description': '新加坡国立大学计算机学院创始院长', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team2.png' },
    // { 'name': '杨宗桦', 'position': '科学家', 'description': '香港城市大学教授\n多媒体检索计算食物识别专家', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team3.png' },
    // { 'name': '佘俊明', 'position': '商业顾问', 'description': '新加坡创新企业商业发展专家', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team4.png' },
    // { 'name': '张钹', 'position': '科学家', 'description': '中国科学院院士\n清华大学人工智能研究院院长', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team5.png' },
    // { 'name': '吴飞', 'position': '科学家', 'description': '浙江大学计算机学院副院长\n浙江大学计算机学院人工智能研究所所长', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team6.png' },
    // { 'name': 'Doris Young', 'position': '代谢症顾问', 'description': '新加坡国立大学家庭医学系主任', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team7.png' },
    // { 'name': 'James Best', 'position': '糖尿病顾问', 'description': '南洋理工大学医学院院长', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team8.png' },
];
export const thinks = [
    // { 'name': '明朝燕', 'position': '首席执行官', 'description': '新加坡国立大学博士\n新加坡国立大学医学院讲师\n浙江大学计算机学院研究员', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team1.png' },
    { 'name': '蔡达成', 'position': '科学家', 'description': '新加坡国立大学计算机学院创始院长', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team2.png' },
    { 'name': '杨宗桦', 'position': '科学家', 'description': '香港城市大学教授\n多媒体检索计算食物识别专家', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team3.png' },
    { 'name': '佘俊明', 'position': '商业顾问', 'description': '新加坡创新企业商业发展专家', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team4.png' },
    { 'name': '张钹', 'position': '科学家', 'description': '中国科学院院士\n清华大学人工智能研究院院长', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team5.png' },
    { 'name': '吴飞', 'position': '科学家', 'description': '浙江大学计算机学院副院长\n浙江大学计算机学院人工智能研究所所长', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team6.png' },
    { 'name': 'Doris Young', 'position': '代谢症顾问', 'description': '新加坡国立大学家庭医学系主任', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team7.png' },
    { 'name': 'James Best', 'position': '糖尿病顾问', 'description': '南洋理工大学医学院院长', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/team8.png' },
];
export const software = [
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/pytorch-logo.png', 'caption': 'Pytorch', 'showCaption': false },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/docker-logo.png', 'caption': 'Docker', 'showCaption': false },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/react-logo.png', 'caption': 'ReactJS', 'showCaption': false },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/nginx-logo.png', 'caption': 'Nginx', 'showCaption': false }
];
export const universities = [
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/zju-logo.png', 'caption': 'ZJU', 'showCaption': false },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/nus-logo.png', 'caption': 'NUS', 'showCaption': false },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/tsinghua-logo.png', 'caption': 'Tsinghua', 'showCaption': false },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/uos-logo.png', 'caption': 'UoS', 'showCaption': false }
];
export const collaboratorIcons = [
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/health-center.png', 'text': '健康中心' },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/check-up.png', 'text': '体检中心' },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/fitness.png', 'text': '健身中心' },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/health-care.png', 'text': '员工保健' },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/desease-management.png', 'text': '慢病管理' },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/new-born.png', 'text': '妇幼营养' }
];
export const collaborators = [
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/active8me-logo.png', 'caption': 'active8me', 'showCaption': false },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/visenze-logo.png', 'caption': 'Visenze', 'showCaption': false },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/sgh-logo.png', 'caption': 'SGH', 'showCaption': false },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/nuh-logo.png', 'caption': 'NUH', 'showCaption': false },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/novi-logo.png', 'caption': 'Novi', 'showCaption': false },
    { 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/haier-logo.png', 'caption': 'Haier', 'showCaption': false }
];

export const qrcodes = [
    { 'title': '公众号-营养知食', 'description': '扫码关注，获取更多营养细节', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/qrcode4.png' },
    { 'title': '客服微信号', 'description': '加好友，成为您身边的营养师', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/qrcode2.png' },
    { 'title': '小程序-知食AI营养师', 'description': '记录饮食，获得更精准的饮食推荐', 'image': 'https://pinshen-1258665547.cos.ap-shanghai.myqcloud.com/website/assets/qrcode3.png' },
];
