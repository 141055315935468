import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SyntheticFont from '../../components/SyntheticFont';
import SmallerContainer from '../../components/SmallerContainer';
import { applications, shadowOverlay } from '../../../data/data';



const style = (theme) => ({
    root: {
        minHeight: '701px',
        padding: '34px 0px',
        boxSizing: 'border-box',
        textAlign: 'center',
        flexGrow: 1,
        backgroundImage: 'url("https://dietlens-1258665547.cos.ap-shanghai.myqcloud.com/mini-app-image/defaultImage/website-applications-background.png")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    applicationItem: {
        position: 'relative',
        width: '266px',
        height: '338px',
        boxSizing: 'content-box',
        textAlign: 'center',
        cursor: 'pointer',
        outline: 'none'
    },
    imgContainer: {
        width: '266px',
        height: '338px',
        position: 'relative'
    },
    image: {
        position: 'absolute',
        display: 'block',
        margin: 'auto'
    },
    title: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#FFFFFF',
        writingMode: 'vertical-lr',
        WebkitWritingMode: 'vertical-lr',
        MsWritingMode: 'vertical-lr'
    },
    overlay: {
        position: 'absolute',
        top: '4px',
        left: '9px',
        display: 'block',
        margin: 'auto'
    },
    syntheticFont: {
        marginBottom: '100px'
    },
    syntheticFontUpper: {
        color: '#FFFFFF'
    },
    syntheticFontLower: {
        color: 'rgba(255, 255, 255, 0.22)'
    },
    description: {
        marginTop: '57px',
        position: 'relative',
        left: '18px',
        display: 'inline-block',
        fontSize: '14px',
        color: '#FFFFFF',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: '5px',
            left: '-18px',
            height: '11px',
            width: '11px',
            borderRadius: '50%',
            backgroundColor: '#FFFFFF'
        }
    }
});

class Applications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appIndex: -1
        };
    }

    swithIndex = () => {

    }

    focus = (index) => () => {
        this.setState({
            appIndex: index
        })
    }

    blur = () => {
        this.setState({
            appIndex: -1
        })
    }

    render() {
        const classes = this.props.classes;
        const containerConfig = {
            md: 7,
            lg: 11,
            spacing: 0
        }

        return (
            <div className={classes.root}>
                <SmallerContainer config={containerConfig}>
                    <Grid item xs={12}>
                        <SyntheticFont
                            className={classes.syntheticFont}
                            inheritedClasses={{
                                upperText: classes.syntheticFontUpper,
                                lowerText: classes.syntheticFontLower
                            }}
                            upperText={'应用场景'}
                            lowerText={'Applications'}
                        />
                    </Grid>
                    {
                        applications.map((item, index) => {
                            return (
                                <Grid
                                    item
                                    md={6}
                                    lg={'auto'}
                                    className={classes.applicationItem}
                                    key={item.title}
                                    tabIndex={index}
                                    onFocus={this.focus(index)}
                                    onBlur={this.blur}
                                >
                                    <div className={classes.imgContainer}>
                                        <img className={classes.image} src={item.image} alt={item.title} />
                                        {
                                            index === this.state.appIndex ?
                                                <img className={classes.overlay} src={shadowOverlay.image} alt={'shadow'} /> :
                                                null
                                        }
                                        <Typography className={classes.title}>{item.title}</Typography>
                                    </div>
                                </Grid>
                            )
                        })
                    }
                    <Grid item xs={12}>
                        <Typography className={classes.description}>{'基于国际前沿营养学知识图谱，人工智能和大数据分析的私人定制饮食健康管理。'}</Typography>
                    </Grid>
                </SmallerContainer>
            </div>
        );
    }
}

export default withStyles(style)(Applications);
