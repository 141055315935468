import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SyntheticFont from '../../components/SyntheticFont';
import SmallerContainer from '../../components/SmallerContainer';
import { coreTech_icons } from '../../../data/data';



const style = (theme) => ({
    root: {
        minHeight: '657px',
        textAlign: 'center',
        padding: '68px 0px',
        boxSizing: 'border-box'
    },
    overflow: {
        overflowX: 'hidden'
    },
    syntheticFont: {
        marginBottom: '100px'
    },
    coreTechItem: {
        textAlign: 'center',
    },
    image: {
        display: 'block',
        margin: 'auto'
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#211B13',
        marginBottom: '10px'
    },
    description: {
        fontSize: '14px',
        color: '#211B13'
    }
});

class CoreTech extends React.Component {

    render() {
        const classes = this.props.classes;
        const containerConfig = {
            md: 11,
            xs: 12
        }

        return (
            <div className={classes.root}>
                <SmallerContainer className={classes.overflow} config={containerConfig}>
                    <Grid item xs={12}>
                        <SyntheticFont className={classes.syntheticFont} upperText={'核心能力'} lowerText={'Core tech'} />
                    </Grid>
                    {
                        coreTech_icons.map((item) => {
                            return (
                                <Grid item className={classes.coreTechItem} key={item.title}>
                                    <img className={classes.image} src={item.image} alt={item.title} />
                                    <Typography className={classes.title}>{item.title}</Typography>
                                    <Typography className={classes.description}>{item.description}</Typography>
                                </Grid>
                            )
                        })
                    }
                </SmallerContainer>
            </div>
        );
    }
}

export default withStyles(style)(CoreTech);
