import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const style = {
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    content: {
        width: '285px',
        fontSize: '14px',
        letterSpacing: '1px',
        color: '#FFFFFF'
    },
    caption: {
        display: 'flex',
        justifyContent: 'flex-start',
        lineHeight: '24px',
        marginTop: '24px',
        paddingLeft: '40px',
        alignItems: 'center'
    },
    line: {
        width: '58px',
        height: '0px',
        border: '1px solid #EEECEC',
        display: 'inline-block'
    },
    author: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#FFFFFF',
        marginLeft: '11px',
        verticalAlign: 'top',
        whiteSpace: 'pre',
    }
};

class Quotes extends React.Component {
    render() {
        const { classes, quote = {} } = this.props;
        const { content, author } = quote;
        return (
            <div className={classes.root}>
                <Typography className={classes.content} align="left">{content}</Typography>

                <div className={classes.caption}>
                    <div className={classes.line}></div>
                    <Typography className={classes.author}>{author}</Typography>
                </div>
            </div>
        );
    }
}

export default withStyles(style)(Quotes);
