import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SyntheticFont from '../../components/SyntheticFont';
import SmallerContainer from '../../components/SmallerContainer';
import RectangleLabel from '../../components/RectangleLabel';
import { phone_images } from '../../../data/data';



const style = (theme) => ({
    root: {
        minHeight: '995px',
        textAlign: 'center',
        flexGrow: 1,
        padding: '108px 0px 70px 0px',
        boxSizing: 'border-box'
    },
    overflow: {
        overflowX: 'hidden'
    },
    syntheticFont: {
        marginBottom: '50px'
    },
    description: {
        fontSize: '14px',
        color: '#211B13',
        whiteSpace: 'pre-line',
        margin: '26px 0px 62px 0px'
    },
    productsItem: {
        textAlign: 'start',
    },
    image: {
        display: 'block',
        margin: 'auto'
    },
    title: {
        position: 'relative',
        display: 'inline-block',
        top: '0px',
        left: '154px',
        transform: 'translateX(-50%)',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#211B13'
    }
});

class Products extends React.Component {

    render() {
        const classes = this.props.classes;
        const containerConfig = {
            lg: 12
        }

        return (
            <div className={classes.root}>
                <SmallerContainer className={classes.overflow} config={containerConfig}>
                    <Grid item xs={12}>
                        <SyntheticFont className={classes.syntheticFont} upperText={'产品介绍'} lowerText={'Products'} />
                        <RectangleLabel labelName={'知食小程序'}></RectangleLabel>
                        <Typography className={classes.description}>{"AI智能营养师的承载平台，为用户提供全面的膳食营养解决方案；\n为营养师提供便捷的客户管理工具。"}</Typography>
                    </Grid>

                    <Grid
                        container
                        className={classes.overflow}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                    >
                        {
                            phone_images.map((item) => {
                                return (
                                    <Grid item md={4} lg={2} className={classes.productsItem} key={item.title}>
                                        <img className={classes.image} width={'336px'} height={'408px'} src={item.image} alt={item.title} />
                                        <Typography className={classes.title}>{item.title}</Typography>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </SmallerContainer>

            </div>
        );
    }
}

export default withStyles(style)(Products);
