import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const style = {
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    image: {
        display: 'block',
        width: 150,
        height: 150,
        margin: '20px 0px'
    },
    qrtext: {
        color: '#999'
    }
};

class Qrcode extends React.Component {
    render() {
        const { classes, title, image } = this.props;
        return (
            <div className={classes.root}>
                <Typography className={classes.qrtext} variant="body1" align="center">{title}</Typography>
                <img className={classes.image} src={image} alt={title} />
            </div>
        );
    }
}

export default withStyles(style)(Qrcode);
